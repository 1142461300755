<template>
  <div id="request">
    <form>
      <v-text-field
        v-model="contactInfo.name"
        label="Name"
        :color="page.color"
        prepend-icon="mdi-account"
        outlined
        required
      ></v-text-field>
      <v-text-field
        v-model="contactInfo.email"
        label="Email"
        :color="page.color"
        prepend-icon="mdi-email"
        outlined
        required
      ></v-text-field>
      <v-textarea
        v-model="contactInfo.message"
        label="Message"
        :color="page.color"
        outlined
        required
      ></v-textarea>
      <v-btn depressed :color="page.color" id="submit-btn" @click="submit">
        <v-icon class="mr-2">mdi-send</v-icon>
        Submit
      </v-btn>
    </form>
  </div>
</template>

<script>
export default {
  name: "Request",
  props: ["page"],
  data: () => ({
    contactInfo: {
      name: "",
      email: "",
      message: ""
    }
  }),
  methods: {
    submit() {
      console.log("submitted info:", this.contactInfo);
    }
  }
};
</script>

<style lang="scss">
#request {
  max-width: 800px;
  margin: 60px auto 25px;
  padding: 0 8px;
}
#submit-btn {
  min-width: 16rem;
  height: 48px;
  color: white;
  float: right;
}
</style>
